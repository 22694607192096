<template>
  <div
    class="creators-section"
    :class="{
      'creators-section--light': light,
      'creators-section--review': review,
    }"
  >
    <router-link
      v-if="titleLink"
      to="/creators"
      class="creators-section__header"
      :class="{ 'creators-section__header--featured': featured }"
      >{{ value.title }}</router-link
    >
    <div
      v-else
      class="creators-section__header"
      :class="{ 'creators-section__header--featured': featured }"
    >
      {{ value.title }}
    </div>
    <div v-if="creators">
      <div v-if="creators.length">
        <div
          class="creators-section__list"
          :class="{
            'disable-swiper': disableSwiper,
            'creators-section__list--all': all,
          }"
        >
          <div class="creators-section__list-inner">
            <CreatorItem
              v-for="creator in creators"
              :key="creator.id"
              :value="creator"
              @reviewProfile="reviewProfile"
              :removeReviewRequest="value.remove_review_request"
              @remove="reloadCreators()"
              :hmActions="hmActions"
              @refresh="reloadCreators()"
            />
          </div>
          <div class="creators-section__list-swiper-wrapper">
            <v-btn
              class="
                creators-section__list-swiper-control
                creators-section__list-swiper-control-left
              "
              icon
              @click="swipePrev()"
              large
            >
              <v-icon size="45" color="#56565A">$arrow_left_alt</v-icon>
            </v-btn>
            <swiper
              ref="swiper"
              :options="swiperOption"
              class="creators-section__list-swiper"
            >
              <swiper-slide v-for="creator in creators" :key="creator.id">
                <CreatorItem :value="creator" />
              </swiper-slide>
            </swiper>
            <v-btn
              class="
                creators-section__list-swiper-control
                creators-section__list-swiper-control-right
              "
              icon
              @click="swipeNext()"
              large
            >
              <v-icon size="45" color="#56565A">$arrow_right_alt</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="creators-section__loadmore" v-if="!all">
          <v-btn
            v-if="value.preview && preview"
            :elevation="0"
            large
            color="grey"
            :to="`/creators/${value.name}`"
            :disabled="inProgress"
            >See More</v-btn
          >

          <v-btn
            v-else
            :elevation="0"
            large
            color="grey"
            @click="setCreators"
            :disabled="inProgress"
            >Load More</v-btn
          >
        </div>
      </div>
      <div v-else class="no-entries">No result</div>
      <ProfileReviewsDialog
        v-if="creators && openedReviewId != null"
        :creators="creators"
        :dialog="reviewDialog"
        :value="openedReviewId"
        :light="light"
        @close="reviewDialog = false"
        @reviewSubmitted="reviewSubmitted($event)"
        :key="`review-dialog_${openedReviewId}`"
      />
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import CreatorItem from "./CreatorItem";
import ProfileReviewsDialog from "@/components/profile-review/ProfileReviewsDialog.vue";

export default {
  props: {
    value: Object,
    preview: Boolean,
    search: Object,
    disableSwiper: Boolean,
    titleLink: Boolean,
    featured: Boolean,
    light: Boolean,
    removeReviewRequest: Boolean,
    hmActions: Boolean,
    initShowFilters: Boolean,
    review: Boolean,
  },
  components: {
    CreatorItem,
    ProfileReviewsDialog,
  },
  data() {
    return {
      creators: null,
      page: 1,
      all: false,
      inProgress: false,
      swiperOption: {
        speed: 400,
        keyboard: {
          enabled: true,
        },
        slidesPerView: 1,
        loop: true,
        observer: true,
        observeParents: true,
      },
      reviewDialog: false,
      openedReviewId: null,
    };
  },
  created() {
    this.setCreators();
  },
  methods: {
    async setCreators() {
      const params = {
        ...(this.search || {}),
        page: this.page,
        section_name: this.value.name,
      };
      if (this.preview && this.value.preview) params.preview = true;
      this.inProgress = true;
      const { data } = await this.$api.Creator.get(params);
      this.creators = [...(this.creators || []), ...data.list];
      this.page += 1;
      this.all = data.all;
      this.inProgress = false;
    },
    async reloadCreators() {
      this.page = 1;
      const params = {
        ...(this.search || {}),
        page: this.page,
        section_name: this.value.name,
      };
      if (this.preview && this.value.preview) params.preview = true;
      this.inProgress = true;
      const { data } = await this.$api.Creator.get(params);
      this.creators = data.list;
      this.page += 1;
      this.all = data.all;
      this.inProgress = false;
    },
    swipePrev() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    swipeNext() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
    reviewProfile(id) {
      this.openedReviewId = id;
      this.reviewDialog = true;
    },
    async reviewSubmitted(idx) {
      await this.reloadCreators();
      if (this.creators.length > idx + 1) {
        this.reviewProfile(this.creators[idx + 1].id);
      } else {
        this.reviewDialog = false;
      }
    },
  },
  watch: {
    search: {
      deep: true,
      handler() {
        this.creators = null;
        this.page = 1;
        this.reloadCreators();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.creators-section {
  margin-bottom: 66px;
  @media screen and (max-width: 960px) {
    margin-bottom: 48px;
  }

  &__list {
    background: var(--v-background-lighten1);
    border-width: 0 2px 2px 2px;
    border-color: var(--v-dark-base);
    border-style: solid;
    overflow: hidden;
    &::after {
      display: block;
      content: "";
      width: 10px;
    }
    &-inner {
      margin: -2px -2px 0 0;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: -2px;
        width: 10px;
        height: 10px;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgMTAgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTSAwIDAgSCAxMCBWIDEwIEggMCBWIDAgWiIgc3R5bGU9ImZpbGw6IHJnYigyNDgsIDI0OCwgMjQ5KTsiLz4KICA8cGF0aCBkPSJNIDcuMDcxIDcuMDcxIEMgNS4zMTYgOC44MjYgMi42OSAxMCAwIDEwIEwgMCA4IEMgMi4yODEgOCA0LjE1NCA3LjE2IDUuNjU3IDUuNjU3IEMgNy4xNiA0LjE1NCA4IDIuMjgxIDggMCBMIDEwIDAgQyAxMCAyLjY5IDguODI2IDUuMzE2IDcuMDcxIDcuMDcxIFoiIHN0eWxlPSIiLz4KPC9zdmc+");
        background-size: contain;
      }
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    &-swiper {
      &-wrapper {
        display: none;
        @media screen and (max-width: 960px) {
          display: flex;
          position: relative;
        }
      }
      &-control {
        position: absolute;
        top: calc(50% - 17px);
        width: 34px;
        height: 34px;
        z-index: 2;
        margin: 0 10px;
        &-right {
          right: 0;
        }
        &-left {
          left: 0;
        }
      }
    }

    &.disable-swiper {
      .creators-section__list-inner {
        @media screen and (max-width: 960px) {
          display: flex;
        }
      }
      .creators-section__list-swiper-wrapper {
        @media screen and (max-width: 960px) {
          display: none;
        }
      }
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  &__header {
    display: block;
    background: var(--v-background-lighten1);
    border: 2px solid var(--v-dark-base);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 24px 40px;
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: 900;
    text-decoration: none;
    @media screen and (max-width: 960px) {
      padding: 24px 12px;
    }
  }
  &--review &__header {
    color: white !important;
    background: #7b61ff !important;
  }
  &__loadmore,
  .no-entries {
    padding: 40px;
    background: var(--v-background-lighten1);
    border: 2px solid var(--v-dark-base);
    margin-top: -2px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--light {
    .creators-section__header {
      background: white;
      border: 2px solid #262729;
    }

    .creators-section__list {
      background: white;
      border-color: #262729;

      .creator-item {
        border: 2px solid #262729;

        &__name {
          color: #262729;
        }

        &__action--red {
          color: #f8f8f9;
        }
      }

      .creators-section__list-swiper .creator-item {
        border: none;
      }
    }

    .creators-section__loadmore {
      background: white;
      border: 2px solid #262729;

      a {
        color: rgba(0, 0, 0, 0.87);
        background-color: #dcdce1 !important;
        border-color: #dcdce1 !important;
      }
    }

    .no-entries {
      background: white;
    }

    ::v-deep .creator-item__admin-actions > button {
      color: #262729 !important;
    }
  }
}

.disabled-theme {
  .creators-section {
    &__list,
    &__loadmore,
    .no-entries,
    &__header {
      border-color: #262729;
    }

    &__header,
    &__current,
    &__review-form,
    &__reviews {
      border-bottom-color: #262729;
    }
  }
}
</style>
