<template>
  <div class="finance-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">Payments Reports</div>
    <Spinner v-if="loadingList" />
    <div v-if="!loadingList">
      <div class="payments-table__actions">
        <div class="payments-table__action">
          <div class="payments-table__action__label">Filter by:</div>
          <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
          <v-menu
            bottom
            offset-y
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="filtersMenu"
            light
            class="filters-menu"
            content-class="filters-menu__content"
          >
            <template v-slot:activator="{ on }">
              <TableActiveFilters
                v-on="on"
                @openMenu="filtersMenu = true"
                :listOptions="listOptions"
                :fields="filterFields"
                @resetFilter="resetFilter($event)"
                :activeListOption="activeListOption"
              />
            </template>
            <v-card class="filters-menu__content__card">
              <div class="filters-menu__body">
                <v-expansion-panels v-model="filterPanels" multiple flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header> Role </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <SkillsSelector
                        v-model="listOptions.skill_id"
                        placeholder="Role"
                        :rules="[validation.required]"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Creator
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <UsersSelector
                        v-model="listOptions.user_id"
                        placeholder="All"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                        includeBlocked
                        multiple
                        itemTooltip="email"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header> Team </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <QbClassSelector
                        v-model="listOptions.qb_class_id"
                        placeholder="Team"
                        :rules="[validation.required]"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Project
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InternalProjectsSelector
                        v-model="listOptions.internal_project_id"
                        emptyLabel="All"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                        multiple
                        chipText="code"
                        @remove="
                          removeFromFilter('internal_project_id', $event);
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Submitted"
                    quickOptions
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="paid_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Paid"
                    quickOptions
                  />
                </v-expansion-panels>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div v-if="!summarizedData">
        <p style="text-align: center">No Results</p>
      </div>
      <div v-else>
        <div>
          <h2>Totals</h2>
          <v-tabs v-model="tab">
            <v-tab>By Month</v-tab>
            <v-tab>By Project</v-tab>
            <v-tab>By Creator</v-tab>
            <v-tab>By Role</v-tab>
            <v-tab>By Team</v-tab>
          </v-tabs>
        </div>
        <v-tabs-items class="fi__tabs-items" v-model="tab" light>
          <v-tab-item class="fi__report">
            <h2>Totals by Month</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.byMonthData.total"
            >
              Total: {{ summarizedData.byMonthData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.byMonthData.data"
              chartClass="by-month-totals-chart"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Project</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.totalByProjectData.total"
            >
              Total: {{ summarizedData.totalByProjectData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.totalByProjectData.data"
              chartClass="project-totals-chart"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Creator</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.byCreatorData.total"
            >
              Total: {{ summarizedData.byCreatorData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.byCreatorData.data"
              chartClass="creator-totals-chart"
              :leftPadding="150"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Role</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.bySkillData.total"
            >
              Total: {{ summarizedData.bySkillData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.bySkillData.data"
              chartClass="role-totals-chart"
              :leftPadding="180"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Team</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.totalByTeamData.total"
            >
              Total: {{ summarizedData.totalByTeamData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.totalByTeamData.data"
              chartClass="team-totals-chart"
              :leftPadding="135"
            />
          </v-tab-item>
        </v-tabs-items>
        <div class="fi__report">
          <h2>Time to Processing/Paid</h2>
          <TimeChart :data="summarizedData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeChart from "./payments-reports/TimeChart.payments-reports";
import TotalsChart from "./payments-reports/TotalsChart.payments-reports";
import invoicesTableActions from "@/mixins/invoices/invoices-table-actions.mixin";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";
import QbClassSelector from "@/components/selects/QbClassSelector.vue";

export default {
  name: "PaymentsReports",
  head() {
    return {
      title: "Payments Reports",
      titleTemplate: "%s | iU Community",
    };
  },
  mixins: [invoicesTableActions],
  components: {
    TimeChart,
    TotalsChart,
    TableDateFilterPanel,
    TableActiveFilters,
    QbClassSelector,
  },
  data() {
    return {
      summarizedData: null,
      tab: null,
      loadingList: false,
      listOptions: this.getDefaultListOptions(true),
      filterFields: [
        { name: "skill_id", label: "Role" },
        { name: "user_id", label: "Creator" },
        { name: "qb_class_id", label: "Team" },
        { name: "internal_project_id", label: "Project" },
        { name: "created_at", label: "Date Submitted" },
        { name: "paid_at", label: "Date Paid" },
      ],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      const params = this.getParams();
      this.loadingList = true;
      const { data } = await this.$api.PaymentsReport.reports(params);
      this.summarizedData = data;
      this.loadingList = false;
    },
    getParams() {
      const params = { ...this.listParams };
      return params;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/table-filters-menu.scss";
</style>

<style lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &__action {
    .v-input fieldset {
      border-color: #262729 !important;
      border-width: 2px;
    }
  }
}

.fi__report {
  &__subhead {
    font-weight: bold;
    font-size: 0.8em;
    color: #4282ff;
    margin-bottom: 1.5em;
  }
}

.fi__tabs-items {
  background: #f8f8f9 !important;
}
.fi__tabs-items .fi__report {
  padding-top: 2em;
}
.payments-reports-chart {
  width: 100%;
  max-width: 900px;
  height: auto;
  &__axis-label {
    font-size: 12px;
  }
  &__value-labels {
    font-size: 12px;
    font-weight: bold;
    &--processing {
      text:not(.payments-reports-chart__value-label--flipped) {
        text-anchor: end;
        transform: translateX(-0.5em);
      }
      text.payments-reports-chart__value-label--flipped {
        text-anchor: start;
        transform: translateX(0.5em);
      }
    }
    &--paid {
      text {
        text-anchor: start;
        transform: translateX(0.5em);
      }
    }
  }
}
</style>
