<template>
  <div class="ratings-points">
    <div class="ratings-points__rubric-link" v-if="showRubricLink">
      <a
        target="_blank"
        href="https://docs.google.com/document/d/1Jnu610TwE0IDdPJwB6WshbS76h7wEmHWJlpwGq25tP8/edit"
        >Scoring Rubric Document</a
      >
    </div>
    <div
      v-for="(rating, idx) in ratingsData"
      :key="`rating--${rating.uuid}`"
      class="ratings-points__rating"
    >
      <div class="ratings-points__rating__label">
        <v-tooltip v-if="rating.tooltip" top>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs"> {{ rating.title }}</span>
          </template>
          <span>{{ rating.tooltip }}</span>
        </v-tooltip>
        <span v-else>{{ rating.title }}</span>
      </div>
      <Select
        placeholder="Select"
        :items="pointsOptions[idx]"
        :value="localData[rating.uuid]"
        class="ratings-points__rating__select"
        light
        @input="
          localData[rating.uuid] = $event;
          $emit('input', localData);
        "
        :disabled="disabled"
      />
    </div>
    <div>
      <div class="ratings-points__rating__label">Total Rating</div>
      <div class="ratings-points__total">
        <span v-if="noDefinedRatings"></span>
        <span
          v-else-if="anyUndefinedRatings"
          class="ratings-points__total__partial"
          >{{ partialPointsSum }}</span
        >
        <span v-else>{{ pointsSum }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    ratingsData: Array,
    formData: Object,
    disabled: Boolean,
    showRubricLink: Boolean,
  },
  data() {
    return {
      localData: this.formData ? { ...this.formData } : this.initialData(),
      pointsOptions: this.ratingsData.map((ratingData) => {
        return ratingData.allow_zero
          ? [...Array(parseInt(ratingData.points) + 1).keys()]
          : [...Array(parseInt(ratingData.points) + 1).keys()].slice(1);
      }),
    };
  },
  methods: {
    initialData() {
      const data = {};
      this.ratingsData.forEach((item) => {
        data[item.uuid] = undefined;
      });
      return data;
    },
  },
  computed: {
    noDefinedRatings() {
      const values = Object.values(this.localData).filter(
        (item) => typeof item != "undefined"
      );
      return values.length == 0;
    },
    pointsSum() {
      const values = Object.values(this.localData);
      if (values.some((item) => typeof item == "undefined")) return "";
      return _.sum(values.map((item) => parseInt(item)));
    },
    partialPointsSum() {
      const values = Object.values(this.localData).filter(
        (item) => typeof item != "undefined"
      );
      return _.sum(values.map((item) => parseInt(item)));
    },
    anyUndefinedRatings() {
      const values = Object.values(this.localData);
      return values.some((item) => typeof item == "undefined");
    },
  },
};
</script>

<style lang="scss" scoped>
.ratings-points {
  padding: 30px 30px 0;
  display: flex;
  flex-wrap: wrap;
  &__rating {
    &:not(:last-child) {
      margin-right: 56px;
    }
    &__label {
      font-weight: 700;
      color: #e12d1b;
    }
    &__select {
      max-width: 144px;
      border: solid 2px black;
      &::v-deep fieldset {
        border-width: 0;
      }
    }
  }
  &__total {
    display: table-cell;
    height: 50px;
    vertical-align: middle;
    font-weight: 700;
    &__partial {
      opacity: 0.5;
    }
  }
  &__rubric-link {
    width: 100%;
    display: flex;
    justify-content: right;
    transform: translateY(-20px);
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
