<template>
  <div
    class="review-form"
    :class="{ 'review-form--gig': submissionForm.project_type == 'gig' }"
  >
    <div class="review-form__header">
      <div class="review-form__title">
        {{ submissionForm.title }}
      </div>
      <div class="review-form__nav">
        <div class="review-form__nav__position-indicator">
          Submission {{ submissionIndex + 1 }} of
          {{ submissionsLength }}
        </div>
        <div class="review-form__nav__btns">
          <div class="review-form__nav__btn" @click="clickPrevNext('prev')">
            <v-icon
              :color="
                submissionForm.project_type == 'gig' ? 'white' : '#e12d1b'
              "
              >$chevron_left_filled</v-icon
            >
          </div>
          <div class="review-form__nav__btn" @click="clickPrevNext('next')">
            <v-icon
              :color="
                submissionForm.project_type == 'gig' ? 'white' : '#e12d1b'
              "
              >$chevron_right_filled</v-icon
            >
          </div>
          <IncompleteFormDialog
            :dialog="incompleteFormDialog"
            @close="incompleteFormDialog = false"
            @advanceAnyway="advanceAnyway"
          />
        </div>
      </div>
    </div>
    <div class="review-form__content">
      <SubmissionReviewItem
        class="review-form__submission"
        :item="submission"
        :submissionForm="submissionForm"
        :key="`review-form-item__${submission.id}`"
      />
      <v-form
        class="review-form__form"
        @submit.prevent="submitReview('next')"
        v-if="formEnabled"
        ref="form"
      >
        <div class="review-form__form__header">My Review</div>
        <FormRatingSimple
          v-if="submissionForm.rating_type == 'simple'"
          :value="form.rating"
          :projectType="submissionForm.project_type"
          :disabled="!updatesAllowed"
          @input="
            form.rating = $event;
            onFormTouched();
          "
        />
        <FormRatingsMultiCategory
          v-else-if="submissionForm.rating_type == 'multi_category'"
          :ratingsData="submissionForm.ratings_data"
          :formData="form.data"
          :disabled="!updatesAllowed"
          @input="
            form.data = { ...$event };
            onFormTouched();
          "
          :key="`rating-multi--${reviewLoaded}--${form.id}`"
        />
        <FormRatingsMultiCategoryPoints
          v-else-if="submissionForm.rating_type == 'multi_category_points'"
          :ratingsData="submissionForm.ratings_data"
          :formData="form.data"
          :disabled="!updatesAllowed"
          @input="
            form.data = { ...$event };
            onFormTouched();
          "
          :key="`rating-multi-points--${reviewLoaded}--${form.id}`"
          :showRubricLink="submission.project_type == 'gig'"
        />
        <div class="review-form__fields">
          <div class="review-form__inputs">
            <div class="review-form__field">
              <div class="review-form__field__label">My Reviewer Notes</div>
              <div class="review-form__notes">
                <TextArea
                  v-model="form.notes"
                  @input="onFormTouched()"
                  placeholder="Notes"
                  :rows="3"
                  :disabled="!updatesAllowed"
                />
                <div
                  class="review-form__actions"
                  v-if="this.submission.project_type == 'gig'"
                >
                  <SubmissionRequestForm
                    :submissionId="submission.id"
                    :key="`request-form--${submission.id}`"
                    :disabled="!formEnabled"
                  />
                  <SubmissionOfferForm
                    :submissionId="submission.id"
                    :key="`offer-form--${submission.id}`"
                    :formState="submissionForm.state"
                    :disabled="!formEnabled"
                  />
                </div>
              </div>
            </div>
            <input
              type="hidden"
              name="submission_id"
              :value="form.submission_id"
            />
          </div>
        </div>
        <div class="review-form__submit-section">
          <div
            v-if="this.submissionForm.my_review_status == 'completed'"
            class="review-form__submit--already"
          >
            You’re scoresheet has already been submitted.
          </div>
          <v-btn
            v-else
            type="submit"
            class="review-form__submit"
            elevation="0"
            :disabled="!formValid || !updatesAllowed"
            color="white"
            >Save</v-btn
          >
        </div>
      </v-form>
      <div class="review-form__closed" v-else>
        Review is closed for this opportunity
      </div>
    </div>
  </div>
</template>

<script>
import SubmissionRequestForm from "./SubmissionRequestForm";
import SubmissionOfferForm from "./SubmissionOfferForm";
import SubmissionReviewItem from "@/components/review/SubmissionReviewItem.vue";
import FormRatingSimple from "./submission-review-form-ratings/SubmissionReviewFormRatingsSimple.vue";
import FormRatingsMultiCategory from "./submission-review-form-ratings/SubmissionReviewFormRatingsMultiCategory.vue";
import FormRatingsMultiCategoryPoints from "./submission-review-form-ratings/SubmissionReviewFormRatingsMultiCategoryPoints.vue";
import IncompleteFormDialog from "./IncompleteFormDialog";

export default {
  components: {
    SubmissionRequestForm,
    SubmissionOfferForm,
    SubmissionReviewItem,
    FormRatingSimple,
    FormRatingsMultiCategory,
    FormRatingsMultiCategoryPoints,
    IncompleteFormDialog,
  },
  props: {
    submission: Object,
    submissionForm: Object,
    submissionIndex: Number,
    submissionsLength: Number,
  },
  data() {
    return {
      form: {
        notes: null,
        submission_id: this.submission.id,
        rating: null,
        data: null,
      },
      reviewLoaded: false,
      formReady: false,
      requestDialog: false,
      offerDialog: false,
      dir: null,
      incompleteFormDialog: false,
    };
  },
  mounted() {
    this.fetchReview();
  },
  methods: {
    async fetchReview() {
      const { data } = await this.$api.SubmissionRating.getMy({
        submission_id: this.submission.id,
      });

      if (data != "none") this.form = { ...data };
      this.reviewLoaded = true;
    },
    async submitReview(dir) {
      if (this.$refs.form.validate()) {
        const { status, data } = await this.$api.SubmissionRating.save(
          this.form
        );
        if (status == 200) {
          this.$emit("orderInvalid");
          this.$emit(dir || "next", data);
        }
      }
    },
    onFormTouched() {
      this.incompleteFormDialog = false;
      if (this.submissionForm.rating_type == "simple") {
        if (this.form.rating) this.formReady = true;
        else this.formReady = false;
      } else if (this.submissionForm.rating_type == "multi_category") {
        if (!this.form.data) {
          this.formReady = false;
          return;
        }
        const missingRating = this.submissionForm.ratings_data.find(
          (ratingsDataItem) => {
            return !this.form.data[ratingsDataItem.uuid];
          }
        );
        if (!missingRating) this.formReady = true;
        else this.formReady = false;
      } else if (this.submissionForm.rating_type == "multi_category_points") {
        if (!this.form.data) {
          this.formReady = false;
          return;
        }
        const values = Object.values(this.form.data);
        this.formReady = !values.some((item) => typeof item == "undefined");
      }
    },
    formIsIncomplete() {
      this.onFormTouched(); // Just a final check. Probably not needed.
      if (this.submissionForm.rating_type == "simple") {
        return this.formReady;
      } else if (this.submissionForm.rating_type == "multi_category") {
        if (!this.form.data) return false;

        const presentRating = this.submissionForm.ratings_data.find(
          (ratingsDataItem) => {
            return !this.form.data[ratingsDataItem.uuid];
          }
        );
        const missingRating = this.submissionForm.ratings_data.find(
          (ratingsDataItem) => {
            return !this.form.data[ratingsDataItem.uuid];
          }
        );
        return presentRating && missingRating;
      } else if (this.submissionForm.rating_type == "multi_category_points") {
        if (!this.form.data) return false;
        const values = Object.values(this.form.data);
        const hasMissingValues = values.some(
          (item) => typeof item == "undefined"
        );
        const hasPresentValues = values.some(
          (item) => typeof item != "undefined"
        );
        return hasPresentValues && hasMissingValues;
      }
    },
    async clickPrevNext(dir) {
      this.dir = dir;
      if (this.formReady && this.updatesAllowed) {
        await this.submitReview(dir);
      } else if (this.formIsIncomplete()) {
        this.incompleteFormDialog = true;
      } else {
        this.$emit(dir);
      }
    },
    clickRating(uuid, value) {
      this.form.data[uuid] = value;
    },
    advanceAnyway() {
      this.$emit(this.dir);
    },
  },
  computed: {
    formEnabled() {
      return (
        this.submissionForm &&
        ["submitted", "review"].includes(this.submissionForm.state)
      );
    },
    formValid() {
      return (
        this.submissionForm &&
        ["submitted", "review"].includes(this.submissionForm.state)
      );
    },
    updatesAllowed() {
      return (
        this.submissionForm.is_accepting_submission_ratings &&
        this.submissionForm.my_review_status != "completed"
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/review/review-dialog.scss";

.review-form {
  &__form {
    border-top: 2px solid #262729;
    &__header {
      background: #7b61ff;
      padding: 28px 43.75px;
      color: white;
      font-weight: 900;
      font-size: 20px;
      border-bottom: solid 2px #262729;
    }
  }

  &__ratings {
    display: flex;
    border-bottom: 2px solid #262729;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    margin: 0;
    padding: 0;
    &.v-input--is-disabled {
      opacity: 0.5;
    }
  }
  &__ratings::v-deep &__rating {
    flex-basis: 20%;
    flex-grow: 1;
    border-style: solid;
    border-color: #262729;
    border-width: 0 2px 0 0;
    padding: 13px;
    margin: 0;
    color: #262729;
    &:last-child {
      border-width: 0;
    }
    &:hover {
      cursor: pointer;
    }
    .v-label {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #262729;
      transition: 0s;
    }
    .v-input--selection-controls__input {
      display: none;
    }
    &.v-item--active {
      background: var(--v-primary-base);
      .v-label {
        color: white;
      }
    }
  }

  &__fields {
    padding: 20px 30px 30px 30px;
    display: flex;
  }
  &__inputs {
    flex-grow: 1;
  }
  &__field {
    &__label {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &__notes {
    display: flex;
    &::v-deep textarea {
      min-height: 110px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    margin-left: 28px;
  }

  &__submit-section {
    display: flex;
    text-align: center;
    justify-content: stretch;
  }
  &__submit {
    padding: 28px !important;
    height: 78px !important;
    flex-grow: 1;
    font-weight: 900;
    font-size: 20px;
    background: var(--v-primary-base) !important;
    color: white !important;
    border-radius: 0 !important;
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background: white !important;
      color: #262729 !important;
      opacity: 0.5;
    }
    &:hover {
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
      &:hover {
        cursor: default;
      }
    }
  }
  &__submit--already {
    padding: 28px !important;
    height: 78px !important;
    flex-grow: 1;
    font-weight: 900;
    font-size: 20px;
    background: white !important;
    color: #262729 !important;
    opacity: 0.5;
  }
  &__closed {
    text-align: center;
    padding: 28px !important;
    font-weight: 900;
    font-size: 20px;
  }
}

@media screen and (max-width: 960px) {
  .review-form {
    &__ratings {
      flex-wrap: wrap;
      > .submission-dialog__form__rating:last-child {
        border-width: 2px 2px 0 0;
      }
    }
  }
}
</style>
