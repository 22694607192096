<template>
  <div class="audit">
    <div class="page-title">Invoice Audit Trail</div>
    <div class="payments-table-container">
      <table border="2" class="payments-table audit__contents">
        <thead class="payments-table-head">
          <tr>
            <th class="w0 center-align">Event <br />Date/Time</th>
            <th class="w0 center-align">Reported <br />Date/Time</th>
            <th class="w45 center-align">Source</th>
            <th class="w0 center-align">Event</th>
          </tr>
        </thead>
        <tbody v-if="list && list.length">
          <tr
            class="payments-row"
            v-for="item in list"
            :key="`invoiceEvent_${item.id}`"
          >
            <td>
              <span style="white-space: nowrap">
                {{
                  item.event_time
                    ? dateFormatted(item.event_time, "MM/DD/YYYY")
                    : ""
                }}</span
              ><br />
              <span style="white-space: nowrap">
                {{
                  item.event_time
                    ? dateFormatted(item.event_time, "h:mm a")
                    : ""
                }}</span
              >
            </td>
            <td>
              <span style="white-space: nowrap">
                {{
                  item.created_at
                    ? dateFormatted(item.created_at, "MM/DD/YYYY")
                    : ""
                }}</span
              ><br />
              <span style="white-space: nowrap">
                {{
                  item.created_at
                    ? dateFormatted(item.created_at, "h:mm a")
                    : ""
                }}</span
              >
            </td>
            <td>
              <TableRowAvatar
                v-if="item.event_source == 'user'"
                :user="item.user"
              />
              <span class="audit__source" v-else>{{
                eventSources[item.event_source]
              }}</span>
            </td>
            <td style="white-space: nowrap">
              <span>{{ eventTypes[item.event_type] }}</span>
              <span v-if="item.send_notification">
                <v-tooltip top max-width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="14"
                      style="margin-left: 0.5em"
                      v-bind="attrs"
                      v-on="on"
                      >$email_send</v-icon
                    >
                  </template>
                  <span v-if="item.note"
                    >Email notification sent with note: <br /><span
                      style="white-space: pre"
                      >{{ item.note }}</span
                    ></span
                  >
                  <span v-else>Email notification sent</span>
                </v-tooltip>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="loading">
          <tr>
            <td :colspan="4" class="center-align">
              <Spinner />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="4" class="center-align">
              No events have been recorded for this invoice.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  components: { TableRowAvatar },
  mixins: [paymentsTableFormats],
  props: {
    value: String,
  },
  data() {
    return {
      list: [],
      eventSources: {
        quickbooks: "Quickbooks",
        community_site: "Community Site",
      },
      eventTypes: {
        create: "Submitted",
        mark_as_paid: "Marked as Paid",
        mark_as_unpaid: "Marked as Unpaid",
        mark_as_canceled: "Canceled",
        mark_as_uncanceled: "Uncanceled",
        send_to_concur: "Sent to Concur",
        mark_as_processing: "Marked as Processing",
      },
      loading: false,
    };
  },
  mounted() {
    this.fetchAudit();
  },
  methods: {
    async fetchAudit() {
      this.loading = true;
      const { data } = await this.$api.InvoiceEvent.list({
        invoice_id: this.value,
      });
      this.list = data;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
@import "@/styles/payments/payments-row.scss";

.audit {
  background: white;
  padding: 20px 34px;
  &__contents {
    margin-top: 30px;
  }
}
.payments-table {
  &-head {
    color: white;
    background: #7b61ff;
  }
}
</style>
