<template>
  <div class="ratings-multi">
    <div
      v-for="rating in ratingsData"
      :key="`rating--${rating.uuid}`"
      class="ratings-multi__rating"
    >
      <div class="ratings-multi__rating__label">
        {{ rating.title }}
      </div>
      <ProfileRating
        :value="localData[rating.uuid]"
        clickable
        @input="
          localData[rating.uuid] = $event;
          $emit('input', localData);
        "
        :rules="[validation.required]"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import ProfileRating from "@/components/profile-review/ProfileRating.vue";

export default {
  components: {
    ProfileRating,
  },
  props: {
    ratingsData: Array,
    formData: Object,
    disabled: Boolean,
  },
  data() {
    return {
      localData: this.formData ? { ...this.formData } : this.initialData(),
    };
  },
  methods: {
    initialData() {
      const data = {};
      this.ratingsData.forEach((item) => {
        data[item.uuid] = 0.0;
      });
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.ratings-multi {
  padding: 30px 30px 20px;
  display: flex;
  flex-wrap: wrap;
}
.ratings-multi__rating {
  &:not(:last-child) {
    margin-right: 56px;
  }
  &__label {
    font-weight: 700;
    color: #e12d1b;
  }
}
</style>
