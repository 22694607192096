<template>
  <tr class="payments-row">
    <td class="center-align">
      <span class="payments-row__detail-link" @click="$emit('openDialog')">{{
        item.int_id
      }}</span>
    </td>
    <td class="center-align">
      {{ item.created_at ? dateFormatted(item.created_at, "MM/DD/YYYY") : "" }}
    </td>
    <td class="td-amount">{{ item.total_amount | currency }}</td>
    <td class="center-align">
      <router-link
        :to="`/project/${project.id}/members`"
        v-for="project in uniqProjects(item)"
        :key="project.id"
      >
        {{ project.code }}
      </router-link>
    </td>
    <td class="center-align">
      <router-link
        :to="
          gig.hire_request_monday_com_item_id
            ? `/hire-requests/${gig.hire_request_monday_com_item_id}`
            : `/gig/${gig.id}`
        "
        v-for="gig in uniqGigs(item)"
        :key="gig.id"
      >
        {{ gig.title }}
      </router-link>
    </td>
    <td>
      <TableRowAvatar
        :user="item.user"
        :path="`/p/${item.user.username || item.user.id}/payments`"
      />
    </td>
    <td
      class="invoice__status center-align"
      :class="`invoice__status--${item.status}`"
    >
      {{ statuses[item.status] }}
    </td>
    <td>
      <div class="td-select">
        <div class="td-select-holder">
          <Checkbox
            class="td-select__checkbox"
            @input="$emit('batchSelect', $event)"
            :value="selected"
          />
        </div>
        <ActionsMenu
          class="payments-row__actions-toggle"
          v-if="currentUser.is_finance || currentUser.is_people_team"
          @close="menu = false"
          @toggle="menu = !menu"
          :menu="menu"
          :themeDisabledPage="themeDisabledPage"
        >
          <v-btn
            @click="$emit('openDialog')"
            color="#262729"
            elevation="0"
            dark
          >
            View Invoice
          </v-btn>
          <v-btn @click="download(item)" color="#56565A" elevation="0" dark>
            Download PDF
          </v-btn>
          <v-btn @click="markAsPaid" color="#7B61FF" elevation="0" dark>
            Mark as Paid
          </v-btn>
          <v-btn
            @click="$emit('openCancelDialog')"
            color="primary"
            elevation="0"
            dark
            v-if="item.status != 'canceled'"
          >
            Cancel Invoice
          </v-btn>
          <v-btn
            @click="$emit('openAuditDialog')"
            color="#262729"
            elevation="0"
            dark
          >
            View Audit Trail
          </v-btn>
        </ActionsMenu>
      </div>
    </td>
  </tr>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import fiActionsMixin from "@/mixins/invoices/fi-actions.mixin";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";

export default {
  mixins: [themeDisabledPageMixin, paymentsTableFormats, fiActionsMixin],
  components: { TableRowAvatar },
  props: {
    item: Object,
    selected: Boolean,
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    refresh() {
      this.menu = false;
      this.$emit("refresh");
    },
    async markAsPaid() {
      await this.fiMarkAsPaid(this.item);
      this.refresh();
    },
    download({ id }) {
      this.$api.Invoice.download({ id, concur: true });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";
</style>
