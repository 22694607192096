var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"tag-selector",class:{
    multiple: _vm.multiple,
    'hidden-input': _vm.multiple && _vm.maximum && _vm.value && _vm.value.length >= _vm.maximum,
  },attrs:{"value":_vm.value,"items":_vm.items,"chips":_vm.multiple,"outlined":"","min-height":"40","label":_vm.label,"multiple":_vm.multiple,"search-input":_vm.localSearch,"item-text":_vm.itemText,"item-value":_vm.itemValue,"deletable-chips":_vm.multiple,"clearable":!_vm.multiple,"hide-details":"auto","rules":_vm.rules,"menu-props":{
    closeOnContentClick:
      _vm.multiple && _vm.maximum && _vm.value && _vm.value.length >= _vm.maximum,
    light: _vm.light,
    contentClass: _vm.menuClass,
  },"dense":"","append-icon":!_vm.appendIcon && _vm.appendIcon !== '' ? '$chevron_down' : _vm.appendIcon,"placeholder":_vm.placeholder,"elevation":"0","light":_vm.light,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)},"update:searchInput":function($event){_vm.localSearch=$event},"update:search-input":function($event){_vm.localSearch=$event}},scopedSlots:_vm._u([(_vm.chipText)?{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"close":""},on:{"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(_vm._s(data.item[_vm.chipText]))])]}}:null,(_vm.itemTooltip)?{key:"item",fn:function(data){return [(_vm.multiple)?_c('div',{staticClass:"v-list-item__action"},[_c('v-btn',{staticClass:"v-list-item__action__btn",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":_vm.isSelected(data.item)
              ? _vm.light
                ? '#56565a'
                : 'var(--v-primary-base)'
              : false}},[_vm._v(_vm._s(_vm.isSelected(data.item) ? "mdi-checkbox-marked" : "$checkbox_off"))])],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"open-delay":"500","top":"","nudge-top":"-12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tag-selector__tooltip-activator"},'div',attrs,false),on),[_c('div',[_vm._v(_vm._s(data.item[_vm.itemText]))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.item[_vm.itemTooltip]))])])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }