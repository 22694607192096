<template>
  <div>
    <v-dialog max-width="400" v-model="dialog" persistent>
      <v-card light class="invoice-cancel-dialog">
        <v-btn
          class="dialog-close"
          icon
          @click="close()"
          v-if="state == 'notification_form'"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <div class="invoice-cancel-dialog__state" v-if="state == 'initial'">
          <div class="invoice-cancel-dialog__msg">
            <p>
              If this invoice was created by mistake, or can not be processed,
              this will remove it from payments lists on Community.
            </p>
            <p>
              <b>Are you sure you want to mark this invoice as canceled?</b>
            </p>
          </div>
          <div class="invoice-cancel-dialog__actions">
            <v-btn
              @click="state = 'notification_yes_no'"
              color="#262729"
              dark
              elevation="0"
            >
              Yes
            </v-btn>
            <v-btn @click="$emit('close')" color="#262729" dark elevation="0">
              No
            </v-btn>
          </div>
        </div>
        <div
          class="invoice-cancel-dialog__state"
          v-if="state == 'notification_yes_no'"
        >
          <div class="invoice-cancel-dialog__msg">
            <p>
              Do you want to send a cancellation notification to the contractor?
            </p>
          </div>
          <div class="invoice-cancel-dialog__actions">
            <v-btn
              @click="
                send_notification = true;
                state = 'notification_form';
              "
              color="#262729"
              dark
              elevation="0"
            >
              Yes
            </v-btn>
            <v-btn
              @click="markAsCanceled({})"
              color="#262729"
              dark
              elevation="0"
            >
              No
            </v-btn>
          </div>
        </div>
        <div
          class="invoice-cancel-dialog__state"
          v-if="state == 'notification_form'"
        >
          <div class="invoice-cancel-dialog__msg">
            <p>
              Include a custom note
              <span class="invoice-cancel-dialog__detail">(optional):</span>
            </p>
            <TextArea
              v-model="note"
              placeholder="Note"
              :rows="5"
              class="invoice-cancel-dialog__note"
              :disabled="sending"
            />
          </div>
          <div class="invoice-cancel-dialog__actions">
            <v-btn
              @click="markAsCanceled({ send_notification: true, note: note })"
              color="#262729"
              dark
              elevation="0"
              :disabled="sending"
            >
              Send Notification
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    dialog: Boolean,
    value: String,
  },
  data() {
    return {
      state: "initial", // 'initial', 'notification_yes_no', 'notification_form'
      send_notification: false,
      note: "",
      sending: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      setTimeout(() => {
        this.state = "initial";
      }, 500);
    },
    async markAsCanceled(options) {
      this.sending = true;
      const transaction_id = uuidv4();
      this.$store.commit("invoice/setTransactionId", transaction_id);
      const params = {
        id: this.value,
        transaction_id,
      };
      if (options.send_notification) {
        params.send_notification = true;
        const note = options.note?.trim();
        if (note) params.note = note;
      }
      const response = await this.$api.Invoice.markAsCanceled(params);
      this.handleResponse(response);
    },
    handleResponse(response) {
      this.sending = false;
      if (response.data.error) {
        this.$alert(response.data.error, "error");
      } else {
        this.$alert("Success", "success");
        this.$emit("refresh");
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice-cancel-dialog {
  font-weight: bold;
  text-align: center;
  padding: 35px;
  &__actions > *:not(:last-child) {
    margin-right: 1em;
  }
  ::v-deep
    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #272727 !important;
  }
  &__note {
    margin-bottom: 16px;
  }
  &__detail,
  &__note {
    font-weight: normal;
  }
}
</style>
