<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="page-title">{{ title }}</div>
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w0 center-align">Invoice</th>
            <th>
              <div>Date Created</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Created"
                isDate
              />
            </th>
            <th class="w0 center-align">Amount</th>
            <th class="w0 center-align" v-if="completedPayments">
              <div>Date Paid</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="paid_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Paid"
                isDate
              />
            </th>
            <th class="w0 center-align">Quantity</th>
            <th>
              <div>Project(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w45">
              <div>Gig(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0">
              <div>Status</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="status"
                fieldLabel="Status"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0">Review</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <CreatorPaymentsTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
            @openDialog="
              dialog = true;
              activeInvoiceId = item.id;
            "
            :completedPayments="completedPayments"
            @refresh="$emit('refresh')"
            @openAuditDialog="
              auditDialog = true;
              activeInvoiceId = item.id;
            "
            @openCancelDialog="
              cancelDialog = true;
              activeInvoiceId = item.id;
            "
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="completedPayments ? 9 : 10" class="center-align">
              No payments
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
    <v-dialog max-width="700" v-model="dialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="dialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <InternalInvoice
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`hminvoice-${activeInvoiceId}`"
          @refresh="
            dialog = false;
            activeInvoiceId = null;
            $emit('refresh');
          "
        />
      </v-card>
    </v-dialog>
    <v-dialog max-width="800" v-model="auditDialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="auditDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <InvoiceAuditTrail
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`hminvoice-${activeInvoiceId}`"
        />
      </v-card>
    </v-dialog>
    <InvoiceCancelDialog
      :dialog="cancelDialog"
      :value="activeInvoiceId"
      :key="`invoice-cancel-dialog-${activeInvoiceId}`"
      @close="cancelDialog = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import InternalInvoice from "@/components/work-assignments/InternalInvoice";
import InvoiceAuditTrail from "@/components/work-assignments/InvoiceAuditTrail";
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import CreatorPaymentsTableRow from "./table-row.creator-payments.vue";
import TableHeaderActions from "@/components/tables/TableHeaderActions";
import InvoiceCancelDialog from "@/components/work-assignments/InvoiceCancelDialog";

export default {
  mixins: [themeDisabledPageMixin],
  components: {
    InternalInvoice,
    CreatorPaymentsTableRow,
    TableHeaderActions,
    InvoiceAuditTrail,
    InvoiceCancelDialog,
  },
  props: {
    title: String,
    items: Array,
    completedPayments: Boolean,
    listOptions: Object,
  },
  data() {
    return {
      dialog: false,
      activeInvoiceId: null,
      auditDialog: false,
      cancelDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &-head {
    color: white;
    background: #7b61ff;
  }
}
</style>
