<template>
  <div>
    <div class="actions-menu-wrapper">
      <v-overlay :value="menu" @click.native.stop="$emit('close')" />
      <v-menu
        content-class="actions-menu"
        bottom
        :offset-y="false"
        :close-on-click="false"
        :close-on-content-click="false"
        v-model="menu"
        :light="themeDisabledPage"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            size="17"
            @click="$emit('toggle')"
            v-bind="attrs"
            v-on="on"
            :color="color || 'currentColor'"
            class="actions-menu__toggle-icon"
            >$ellipsis_v</v-icon
          >
        </template>
        <v-card class="actions-menu__content">
          <v-btn class="dialog-close" icon @click="$emit('close')">
            <v-icon>$close</v-icon>
          </v-btn>
          <div class="actions-menu__header">Admin Access</div>
          <div class="actions-menu__body">
            <div class="actions-menu__main" v-if="!assignForReviewMenu">
              <v-btn
                @click="$emit('reviewProfile', value.id)"
                color="primary"
                elevation="0"
                v-if="currentUser.is_reviewer"
              >
                Review Profile
              </v-btn>
              <v-btn
                @click="assignForReviewMenu = true"
                color="#56565A"
                elevation="0"
                dark
                v-if="currentUser.is_reviewer"
              >
                Assign for Review
              </v-btn>
              <div
                class="compound-btn"
                v-if="
                  (currentUser.is_reviewer ||
                    currentUser.is_hiring_manager ||
                    currentUser.is_finance ||
                    currentUser.is_people_team) &&
                  (value.contact
                    ? value.contact.email
                    : value.contact_email || value.email)
                "
              >
                <v-btn
                  color="#56565A"
                  elevation="0"
                  dark
                  @click="
                    copyEmailToClipboard(
                      value.contact
                        ? value.contact.email
                        : value.contact_email || value.email
                    )
                  "
                >
                  Copy Email
                </v-btn>
              </div>
              <v-btn
                color="#56565A"
                elevation="0"
                dark
                v-if="
                  currentUser.is_reviewer ||
                  currentUser.is_hiring_manager ||
                  currentUser.is_finance ||
                  currentUser.is_people_team
                "
                :href="`tel:${value.phone}`"
                target="_blank"
              >
                Call Creator
              </v-btn>
              <v-btn
                color="#56565A"
                elevation="0"
                dark
                v-if="
                  currentUser.is_people_team ||
                  currentUser.is_hiring_manager ||
                  currentUser.is_finance
                "
                :to="`/p/${value.username || value.id}/agreements`"
              >
                View Agreements
              </v-btn>
              <v-btn
                color="#56565A"
                elevation="0"
                dark
                v-if="
                  currentUser.is_people_team ||
                  currentUser.is_hiring_manager ||
                  currentUser.is_finance
                "
                :to="`/p/${value.username || value.id}/hire-requests`"
              >
                View Hire Requests
              </v-btn>
              <v-btn
                color="#56565A"
                elevation="0"
                dark
                v-if="
                  currentUser.is_hiring_manager ||
                  currentUser.is_people_team ||
                  currentUser.is_finance
                "
                :to="`/p/${value.username || value.id}/payments`"
              >
                View Payments
              </v-btn>
              <div v-if="currentUser.is_people_team || currentUser.is_finance">
                <v-btn
                  color="#56565A"
                  elevation="0"
                  dark
                  v-if="
                    value.onboarding_details &&
                    value.onboarding_details.w9_status != 'SIGNED'
                  "
                  @click="onboardW9Dialog = true"
                >
                  W-9 Completed
                </v-btn>
                <v-btn
                  color="#56565A"
                  elevation="0"
                  dark
                  v-if="
                    value.onboarding_details &&
                    value.onboarding_details.w9_overridden
                  "
                  @click="onboard('w9', false)"
                >
                  W-9 Not Completed
                </v-btn>
                <v-btn
                  color="#56565A"
                  elevation="0"
                  dark
                  v-if="
                    value.onboarding_details &&
                    value.onboarding_details.ach_status != 'SIGNED'
                  "
                  @click="onboardACHDialog = true"
                >
                  ACH Completed
                </v-btn>
                <v-btn
                  color="#56565A"
                  elevation="0"
                  dark
                  v-if="
                    value.onboarding_details &&
                    value.onboarding_details.ach_overridden
                  "
                  @click="onboard('ach', false)"
                >
                  ACH Not Completed
                </v-btn>
                <template v-if="currentUser.is_finance">
                  <v-btn
                    color="#56565A"
                    elevation="0"
                    dark
                    @click="onboardLinkVendorDialog = true"
                  >
                    {{
                      value.onboarding_details.qb_is_linked
                        ? "Re-link QBO Vendor"
                        : "Link QBO Vendor"
                    }}
                  </v-btn>
                  <v-btn
                    color="#56565A"
                    elevation="0"
                    dark
                    v-if="
                      value.onboarding_details.qb_is_linked &&
                      value.onboarding_details.qb_id
                    "
                    :href="`https://app.qbo.intuit.com/app/vendordetail?nameId=${value.onboarding_details.qb_id}`"
                    target="_blank"
                  >
                    View QBO Vendor
                    <v-icon x-small color="#fff" class="mb-1 ml-1">
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
              </div>
            </div>
            <div class="actions-menu__assign" v-else>
              <v-btn
                @click="assignForReviewMenu = false"
                color="#56565A"
                elevation="0"
                class="actions-menu__assign__back"
              >
                Back
              </v-btn>
              <p>Search for a reviewer to evaluate this creator.</p>
              <UsersSelector
                v-model="assigneeId"
                :roles="['reviewer']"
                append-icon=""
                placeholder="@..."
                class="creator-item__admin-actions-menu__review-assignee-select"
                @change="assigneeChange"
                itemTooltip="email"
              >
              </UsersSelector>
              <v-btn
                @click="assignReviewer(value.id, assigneeId)"
                color="primary"
                elevation="0"
                :disabled="!assigneeId || assigneeSent"
                class="actions-menu__assign__send"
              >
                Send
              </v-btn>
              <div v-if="assigneeSent" class="actions-menu__assign__message">
                Review request sent.
              </div>
            </div>
          </div>
        </v-card>
      </v-menu>
      <slot></slot>
    </div>
    <div v-if="value">
      <AreYouSureDialog
        :dialog="onboardW9Dialog"
        @yes="onboard('w9', true)"
        @no="onboardW9Dialog = false"
      >
        <p>
          If a creator filled out a W-9 in person or outside the Community
          platform, this will mark them as such on Community. The Community site
          will not have a record of this agreement; this is just an override.
        </p>
        <p>
          Are you sure you want to mark this creator as having filled out a W-9?
        </p>
      </AreYouSureDialog>
      <AreYouSureDialog
        :dialog="onboardACHDialog"
        @yes="onboard('ach', true)"
        @no="onboardACHDialog = false"
      >
        <p>
          If a creator filled out an ACH authorization form in person or outside
          the Community platform, this will mark them as such on Community. The
          Community site will not have a record of this agreement; this is just
          an override.
        </p>
        <p>
          Are you sure you want to mark this creator as having filled out an ACH
          authorization form?
        </p>
      </AreYouSureDialog>
      <AreYouSureDialog
        :dialog="onboardLinkVendorDialog"
        @yes="onboard('qbo_link', onboardLinkVendorId)"
        @no="onboardLinkVendorDialog = false"
        :yes-disabled="!onboardLinkVendorURLValid"
      >
        <p>
          Paste the URL for the QBO Vendor and press link to associate them with
          that vendor in Community. For example:
          <code>https://app.qbo.intuit.com/app/vendordetail?nameId=1234</code>
        </p>
        <TextField v-model="onboardLinkVendorURL" class="mb-4" />
        <p v-if="onboardLinkVendorId">
          Extracted vendor ID: {{ onboardLinkVendorId }}
        </p>
        <template #yes> Link </template>
        <template #no> Cancel </template>
      </AreYouSureDialog>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/lib";
import onboardMixin from "@/mixins/onboard.mixin";

export default {
  mixins: [onboardMixin],
  props: {
    value: Object,
    menu: Boolean,
    themeDisabledPage: Boolean,
    color: String,
  },
  data() {
    return {
      assignForReviewMenu: false,
      assigneeId: null,
      assigneeSent: false,
      onboardW9Dialog: false,
      onboardACHDialog: false,
    };
  },
  methods: {
    async assignReviewer(id, assigneeId) {
      const res = await this.$api.Creator.assignReviewer({
        id,
        reviewer_id: assigneeId,
      });
      if (res.status == 200)
        this.$nextTick(() => {
          this.assigneeSent = true;
        });
    },
    assigneeChange() {
      this.assigneeSent = false;
    },
    copyEmailToClipboard(email) {
      copyToClipboard(email);
      this.$alert("Creator’s email copied to clipboard");
    },
  },
};
</script>

<style scoped lang="scss">
.actions-menu-wrapper {
  position: relative;
  > .v-icon {
    display: block;
  }
}

.actions-menu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  width: 245px;
  border-radius: 10px;
  text-align: center;
  box-shadow: none;
  &__content {
    position: relative;
    background: var(--v-background-base);
  }
  .dialog-close {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }
  .v-btn {
    font-weight: bold;
  }
  &__header {
    width: 100%;
    background: var(--v-secondary-darken1);
    color: white;
    min-height: 36px;
    border-radius: 10px 10px 0 0;
    font-size: 20px;
    font-weight: 900;
    padding: 12px;
  }
  &__main {
    padding-top: 55px;
    padding-bottom: 49px;
    .v-btn:not(.v-btn--icon):not(:last-child) {
      margin-bottom: 21px;
    }
  }
  &__main {
    padding-top: 27px;
    padding-bottom: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .v-btn,
    .compound-btn {
      min-width: 131px;
      padding: 0 10px;
      &:not(:last-child) {
        margin-bottom: 21px;
      }
    }
    .compound-btn {
      display: flex;
      > .v-btn {
        margin-bottom: 0 !important;
      }
    }
  }

  &__assign {
    padding-top: 37px;
    padding-bottom: 49px;
    .v-input.tag-selector {
      margin-left: 38px;
      margin-right: 38px;
      width: auto;
    }
    .v-btn:not(.v-btn--icon),
    .v-input.tag-selector {
      &:not(:last-child) {
        margin-bottom: 23px;
      }
    }
    &__back {
      color: white !important;
    }
    &__send {
      &::v-deep {
        &.theme--light.v-btn.v-btn--disabled,
        &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          color: white !important;
          background-color: var(--v-primary-base) !important;
          opacity: 0.5;
        }
      }
    }
  }
  &__review-assignee-select {
    border: 1px solid #dcdce1;
    border-radius: 10px;
    max-width: 170px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    &::v-deep {
      &.tag-selector.v-input fieldset {
        border: none;
      }
      &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 40px !important;
      }
      &.v-text-field--outlined.v-input--dense .v-label {
        top: 10px;
      }
      &.tag-selector.v-text-field--enclosed .v-input__append-inner {
        margin-top: 8px !important;
      }
    }
  }
  p {
    margin-left: 1em;
    margin-right: 1em;
  }
  &__toggle-icon {
    transform: none !important;
    display: block;
  }
}

.onboard-dialog {
  p {
    font-weight: normal;
  }
}

.disabled-theme {
  .actions-menu {
    &__header {
      background: var(--v-secondary-darken1);
      color: white;
    }
    &__content {
      background: #dcdce1 !important;
    }
    &__assign__back {
      background-color: #56565a !important;
      color: white !important;
    }
    &__assign__send {
      background-color: #e12d1b !important;
      color: white !important;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }
  .profile-view__header {
    .actions-menu__toggle-icon {
      color: white !important;
      margin-left: 20px;
      margin-top: 6px;
    }
  }
}
</style>
