<template>
  <div class="creator-resolver">
    <div class="creator-resolver__fields">
      <div class="monday-on__issue-msg" v-if="!localValue">
        No creator was found with the email
        <span class="monday-on__issue-msg--emphasis">{{
          mondayComItem.email
        }}</span
        >. Does the creator have an account under a different email?
      </div>
      <FormRow label="Creator *" light>
        <UsersSelector
          v-model="localValue"
          placeholder="Creator"
          @change="creatorChange"
          class="creator-resolver__select"
          itemTooltip="email"
        />
      </FormRow>
    </div>
    <div v-if="onboardingNeeded">
      <div
        v-if="creator.onboarding_details.msa_status == 'NONE'"
        class="creator-resolver__agreement-check"
      >
        <div v-if="!sents.msa_contractor && !sents.msa_crew">
          <div class="monday-on__issue-msg">
            This creator has not signed a
            <span class="monday-on__issue-msg--emphasis">current-year MSA</span
            >. Would you like to send them one?
          </div>
          <div class="creator-resolver__actions">
            <v-btn
              color="#E12D1B"
              @click="sendAgreement('msa_contractor')"
              elevation="0"
              >Send {{ year }} Contractor MSA</v-btn
            >
            <v-btn
              color="#E12D1B"
              @click="sendAgreement('msa_crew')"
              elevation="0"
              >Send {{ year }} Crew MSA</v-btn
            >
          </div>
        </div>
        <div class="monday-on__issue-msg" v-else>
          {{ `${creator.display_name}’s ${year} MSA has been sent!` }}
        </div>
      </div>
      <div
        v-if="creator.onboarding_details.w9_status == 'NONE'"
        class="creator-resolver__agreement-check"
      >
        <div v-if="!sents.w9">
          <div class="monday-on__issue-msg">
            This creator has not filled out a
            <span class="monday-on__issue-msg--emphasis">W-9</span>. Would you
            like to send them one?
          </div>
          <div class="creator-resolver__actions">
            <v-btn color="#E12D1B" @click="sendAgreement('w9')" elevation="0"
              >Send W-9</v-btn
            >
            <v-btn color="#56565A" elevation="0" @click="onboardW9Dialog = true"
              >W-9 Completed</v-btn
            >
          </div>
        </div>
        <div class="monday-on__issue-msg" v-else>
          {{ `${creator.display_name}’s W-9 has been sent!` }}
        </div>
      </div>
      <div
        v-if="creator.onboarding_details.ach_status == 'NONE'"
        class="creator-resolver__agreement-check"
      >
        <div v-if="!sents.ach_authorization">
          <div class="monday-on__issue-msg">
            This creator has not filled out an
            <span class="monday-on__issue-msg--emphasis">ACH authorization</span
            >. Would you like to send them one?
          </div>
          <div class="creator-resolver__actions">
            <v-btn
              color="#E12D1B"
              @click="sendAgreement('ach_authorization')"
              elevation="0"
              >Send ACH Authorization Form</v-btn
            >
            <v-btn
              color="#56565A"
              elevation="0"
              @click="onboardACHDialog = true"
              >ACH Completed</v-btn
            >
          </div>
        </div>
        <div class="monday-on__issue-msg" v-else>
          {{
            `${creator.display_name}’s ACH authorization form has been sent!`
          }}
        </div>
      </div>
      <div class="creator-resolver__actions">
        <v-btn color="#7B61FF" @click="continueToForm" elevation="0"
          >Skip This</v-btn
        >
      </div>
    </div>
    <div v-else>
      <div class="monday-on__issue-msg" v-if="msaSent">
        {{ `${creator.display_name}’s ${year} MSA has been sent!` }}
      </div>
      <div class="creator-resolver__actions">
        <v-btn color="#7B61FF" @click="continueToForm" elevation="0"
          >Continue to Hire Request Form</v-btn
        >
      </div>
    </div>
    <AreYouSureDialog
      :dialog="onboardW9Dialog"
      @yes="onboard('w9', true)"
      @no="onboardW9Dialog = false"
    >
      <p>
        If a creator filled out a W-9 in person or outside the Community
        platform, this will mark them as such on Community. The Community site
        will not have a record of this agreement; this is just an override.
      </p>
      <p>
        Are you sure you want to mark this creator as having filled out a W-9?
      </p>
    </AreYouSureDialog>
    <AreYouSureDialog
      :dialog="onboardACHDialog"
      @yes="onboard('ach', true)"
      @no="onboardACHDialog = false"
    >
      <p>
        If a creator filled out an ACH authorization form in person or outside
        the Community platform, this will mark them as such on Community. The
        Community site will not have a record of this agreement; this is just an
        override.
      </p>
      <p>
        Are you sure you want to mark this creator as having filled out an ACH
        authorization form?
      </p>
    </AreYouSureDialog>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    mondayComItem: Object,
  },
  data() {
    return {
      localValue: this.value,
      creator: null,
      year: new Date().getFullYear(),
      sents: {
        msa_contractor: false,
        msa_crew: false,
        w9: false,
        ach_authorization: false,
      },
      msaSent: false,
      w9Sent: false,
      achSent: false,
      pleaseWait: false,
      onboardW9Dialog: false,
      onboardACHDialog: false,
    };
  },
  mounted() {
    this.initCheckCreator();
  },
  methods: {
    async initCheckCreator() {
      await this.checkCreator();
      if (this.creator && !this.onboardingNeeded) this.continueToForm();
    },
    async checkCreator() {
      console.info("fetchCreator this", this);
      console.info("fetchCreator this.value", this.localValue);
      console.info(
        "fetchCreator this.mondayComItem?.email",
        this.mondayComItem?.email
      );
      const params = {};
      if (this.localValue) {
        params.id = this.localValue;
      } else if (this.mondayComItem?.email) {
        params.email = this.mondayComItem.email;
      }

      try {
        const { data } = await this.$api.Creator.onboardingDetails(params);
        console.info("fetchCreator data", data);
        this.creator = data;
        this.localValue = this.creator.id;
      } catch (e) {
        if (e.response.status == 404) {
          if (this.localValue) this.$alert("Creator not found", "error");
          this.creator = null;
        } else {
          this.$alert("Something went wrong", "error");
        }
      }
    },
    continueToForm() {
      if (this.creator) this.$emit("input", this.creator);
    },
    async sendAgreement(agreementType) {
      this.pleaseWait = true;
      try {
        const { status } = await this.$api.Agreement.create({
          user_id: this.localValue,
          agreement_type: agreementType,
          msa_year: this.year,
        });
        console.info("status", status);
        this.pleaseWait = false;
        if (status == 200) {
          this.sents[agreementType] = true;
        }
      } catch (e) {
        this.pleaseWait = false;
        this.$alert("Something went wrong", "error");
      }
    },
    async creatorChange() {
      console.info("creatorChange this.localValue", this.localValue);
      console.info("creatorChange this.creator?.id", this.creator?.id);
      if (this.localValue == this.creator?.id) return;
      if (!this.localValue) {
        console.info("localValue watch: no value selected");
        this.creator = null;
      } else {
        console.info("localValue watch: checking Creator");
        await this.checkCreator();
        if (this.creator == null) return;
        if (!this.onboardingNeeded) this.$emit("input", this.creator);
      }
    },
    async onboard(type, status) {
      try {
        await this.$api.User.onboard({
          id: this.localValue,
          type,
          status,
        });
        this.onboardW9Dialog = false;
        this.onboardACHDialog = false;
        await this.checkCreator();
        if (this.creator == null) return;
        if (!this.onboardingNeeded) this.$emit("input", this.creator);
      } catch (e) {
        console.info("e", e);
      }
    },
  },
  computed: {
    onboardingNeeded() {
      if (!this.creator) return false;
      return (
        (this.creator.onboarding_details.msa_status == "NONE" &&
          !this.sents.msa_contractor &&
          !this.sents.msa_crew) ||
        (this.creator.onboarding_details.w9_status == "NONE" &&
          !this.sents.w9) ||
        (this.creator.onboarding_details.ach_status == "NONE" &&
          !this.sents.ach_authorization)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.creator-resolver {
  &__select {
    max-width: 400px;
  }

  &__agreement-check,
  &__fields {
    margin-top: 1em;
  }

  &__actions {
    display: flex;
    margin-top: 1em;
    > *:not(:last-child) {
      margin-right: 1em;
    }
    .v-btn {
      color: white !important;
    }
  }

  ::v-deep {
    .v-input fieldset {
      border-color: #262729;
      border-width: 2px;
    }

    .form-label {
      min-width: 100px;
      max-width: 100px;
    }
  }
}
</style>
