<template>
  <div class="new-hr">
    <v-form ref="form">
      <div class="new-hr__main">
        <v-row>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
            <FormRow label="Creator *" light>
              <UsersSelector
                v-model="form.creator_id"
                placeholder="Creator"
                @change="$emit('creatorChange')"
                itemTooltip="email"
              />
            </FormRow>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
            <FormRow label="Submitted by *" light>
              <UsersSelector
                v-model="form.submitter_id"
                placeholder="Submitted by"
                :rules="[validation.required]"
                :badges="['iustaff']"
                @input="$emit('input', form)"
                itemTooltip="email"
              />
            </FormRow>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
            <FormRow label="Start Date" light>
              <DateTimePicker
                placeholder="Select Date"
                v-model="form.start_date"
                onlyDate
                light
              ></DateTimePicker>
            </FormRow>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
            <FormRow label="End Date" light>
              <DateTimePicker
                placeholder="Select Date"
                v-model="form.end_date"
                onlyDate
                light
              ></DateTimePicker>
            </FormRow>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 4">
            <FormRow label="Rate Type" light>
              <Select v-model="form.rate_type" :items="rateTypes" light />
            </FormRow>
          </v-col>
          <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 8">
            <v-row>
              <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                <FormRow
                  :label="`${rateTypeLabels[form.rate_type]} Rate`"
                  light
                  v-if="form.rate_type != 'flat_fee'"
                >
                  <TextField
                    v-model="form.rate"
                    :rules="[validation.required]"
                    block
                    type="number"
                    name="rate"
                    prefix="$"
                  />
                </FormRow>
                <FormRow label="Amount" light v-else>
                  <TextField
                    v-model="form.amount"
                    :rules="[validation.required]"
                    block
                    type="number"
                    name="rate"
                    prefix="$"
                  />
                </FormRow>
              </v-col>
              <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                <FormRow label="Expected Hrs." light>
                  <TextField
                    placeholder="Expected Hrs."
                    v-model="form.estimated_hours"
                    number
                  />
                </FormRow>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <FormRow
          label="Description of Services"
          light
          class="form-row--narrow-label"
        >
          <TextArea v-model="form.job_description" />
        </FormRow>
        <FormRow
          label="Additional Rate Details"
          light
          class="form-row--narrow-label"
        >
          <TextArea v-model="form.rate_details" />
        </FormRow>
      </div>
      <div class="new-hr__work-assignments">
        <div class="form-label">Gigs</div>
        <div class="new-hr__work-assignment-list">
          <div
            v-for="(wa, idx) in form.work_assignments"
            :key="`work-assignment_${wa.id || 'new'}_${idx}`"
            class="new-hr__work-assignment"
          >
            <div class="new-hr__work-assignment__fields">
              <v-row>
                <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                  <FormRow
                    label="Project *"
                    light
                    tooltip="Please check with the Finance team to add projects not found."
                  >
                    <InternalProjectsSelector
                      v-model="wa.internal_project_id"
                      :rules="[validation.required]"
                      placeholder="Project Code"
                      class="new-hr__project-selector"
                      @change="projectChange(idx)"
                    />
                  </FormRow>
                </v-col>
                <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                  <FormRow light>
                    <template v-slot:label
                      >Hiring <br class="br-desktop" />Manager *</template
                    >
                    <UsersSelector
                      v-model="wa.hiring_manager_id"
                      placeholder="Hiring Manager"
                      :roles="['hiring_manager']"
                      :rules="[validation.required]"
                      itemTooltip="email"
                    />
                  </FormRow>
                </v-col>
              </v-row>
              <v-row
                class="monday-on__issue-msg new-hr__issue-msg"
                v-if="
                  !wa.internal_project_id &&
                  wa.code_from_monday &&
                  !projectsChanged[idx]
                "
              >
                <div>
                  No project with code
                  <span class="monday-on__issue-msg--emphasis">
                    {{ wa.code_from_monday }}
                  </span>
                  was found. Is this the correct project code? Please check with
                  the Finance team to add projects not found.
                </div>
              </v-row>
              <v-row>
                <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                  <FormRow label="Role *" light>
                    <SkillsSelector
                      v-model="wa.skill_id"
                      placeholder="Role"
                      :rules="[validation.required]"
                      payments
                      @input="selectedSkill = $event"
                    />
                  </FormRow>
                </v-col>
                <v-col :cols="['xs', 'sm'].includes($size) ? 12 : 6">
                  <FormRow light>
                    <template v-slot:label
                      >Team/ <br class="br-desktop" />Department *</template
                    >
                    <QbClassSelector
                      v-model="wa.qb_class_id"
                      placeholder="Team/Department"
                      :rules="[validation.required]"
                      :selectedSkill="selectedSkill"
                    />
                  </FormRow>
                </v-col>
              </v-row>
            </div>

            <div class="new-hr__work-assignment__acitons">
              <v-btn icon @click="$emit('removeGig', idx)"
                ><v-icon>$delete</v-icon></v-btn
              >
            </div>
          </div>
          <div class="new-nr__work-assignments__actions">
            <v-btn color="#4282FF" @click="addNewGig()" elevation="0"
              >+ New Gig</v-btn
            >
          </div>
        </div>
      </div>

      <SubmitFooter :refs="$refs" :submit="submit" inProgressText="Submitting">
        {{ form.id ? "Update Hire Request" : "Create Hire Request" }}
      </SubmitFooter>
    </v-form>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import QbClassSelector from "@/components/selects/QbClassSelector.vue";

export default {
  mixins: [InvoiceLabelsMixin],
  components: { QbClassSelector },
  props: {
    value: Object,
  },
  data() {
    return {
      form: { ...this.value },
      projectsChanged: {},
      selectedSkill: null,
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.form);
    },
    projectChange(idx) {
      console.info("projectChange", idx);
      this.projectsChanged[idx] = true;
    },
    addNewGig() {
      this.$emit("input", this.form);
      this.$nextTick(() => this.$emit("addNewGig"));
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.form = { ...this.value };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.new-hr {
  font-size: 14px;
  &__main {
    padding-bottom: 35px;
    margin-bottom: 35px;
    border-bottom: 2px solid #dcdce1;
  }

  &__work-assignments {
    &__actions {
      margin-top: 4em;
    }
  }

  &__work-assignment {
    display: flex;
    padding: 0 0 20px;
    margin: 0 0 20px;
    &:not(:last-child) {
      border-bottom: 2px solid #dcdce1;
    }
    &-list {
      margin-left: 4em;
    }
    &__fields {
      flex-grow: 1;
    }
  }

  &__issue-msg {
    padding: 0 12px 12px !important;
  }

  .form-label {
    text-align: left;
    font-weight: bold;
    color: #56565a;
  }

  ::v-deep {
    .v-input,
    .v-input:not(.v-input--has-state) {
      fieldset {
        border-color: #262729;
        border-width: 2px;
      }
    }

    .form-label {
      min-width: 100px;
    }
    .form-row--narrow-label .form-label {
      max-width: 100px;
    }

    .col,
    .row {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
@media screen and (max-width: 960px) {
  .new-hr {
    .br-desktop {
      display: none;
    }
    ::v-deep {
      .form-row--narrow-label .form-label {
        max-width: unset;
      }
    }
    &__work-assignments > .form-label {
      font-size: 20px;
    }
    &__work-assignment-list {
      margin-left: 0;
    }
  }
}
</style>
