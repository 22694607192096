import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      statuses: {
        submitted: "Submitted",
        hm_approved: "Submitted", // DEPRECATED
        hm_rejected: "Submitted", // DEPRECATED
        fi_approved: "Submitted", // DEPRECATED
        fi_rejected: "Submitted", // DEPRECATED
        paid: "Completed",
        canceled: "Canceled",
        processing: "Processing",
      },
    };
  },
  methods: {
    async fiMarkAsPaid(invoice) {
      const transaction_id = uuidv4();
      this.$store.commit("invoice/setTransactionId", transaction_id);
      const params = {
        id: invoice.id,
        transaction_id,
      };
      const response = await this.$api.Invoice.markAsPaid(params);
      this.handleResponse(response);
    },
    async fiMarkAsUnpaid(invoice) {
      const transaction_id = uuidv4();
      this.$store.commit("invoice/setTransactionId", transaction_id);
      const params = {
        id: invoice.id,
        transaction_id,
      };
      const response = await this.$api.Invoice.markAsUnpaid(params);
      this.handleResponse(response);
    },
    handleResponse(response) {
      if (response.data.error) {
        this.$alert(response.data.error, "error");
      } else {
        this.dialog = false;
        this.$alert("Success", "success");
        this.$emit("refresh");
      }
    },
  },
};
