<template>
  <div>
    <v-dialog
      width="700"
      v-model="dialog"
      @click:outside="cancel"
      content-class="send-agreement-dialog agreements-dialog"
      light
    >
      <v-card class="send-agreement__content">
        <div class="send-agreement__header">
          <div class="send-agreement__title">Upload Agreement</div>
          <v-btn class="close" icon @click="cancel">
            <v-icon size="30">$close</v-icon>
          </v-btn>
        </div>
        <div class="send-agreement__form">
          <v-form @submit.prevent="submit" ref="form">
            <div>
              *
              <span class="send-agreement__required-hint"
                >Indicates required fields</span
              >
            </div>
            <div>
              <div class="form-row">
                <div class="form-label">Creator*</div>
                <div class="form-field">
                  <UsersSelector
                    v-model="form.user_id"
                    placeholder="Creator"
                    :rules="[validation.required]"
                    itemTooltip="email"
                  />
                </div>
              </div>
              <div v-if="form.user_id">
                <div class="form-row">
                  <div class="form-label">Agreement Type*</div>
                  <Select
                    :items="agreementTypes"
                    item-value="value"
                    item-text="name"
                    v-model="form.agreement_type"
                    :rules="[validation.required]"
                    class="form-field"
                    @input="typeChange"
                  />
                </div>
                <div
                  class="form-row"
                  v-if="
                    'gig_contract' == form.agreement_type &&
                    (user || form.user_id)
                  "
                >
                  <div class="form-label">Hire Request*</div>
                  <HireRequestSelector
                    class="form-field hr-selector"
                    placeholder="Hire Request"
                    v-model="form.hire_request_id"
                    :rules="[validation.required]"
                    :params="{
                      creator_id: user ? user.id : form.user_id,
                    }"
                    :key="`ge-selector-${user ? user.id : form.user_id}`"
                  />
                </div>
                <div
                  class="form-row"
                  v-if="
                    'gig_extension' == form.agreement_type &&
                    (user || form.user_id)
                  "
                >
                  <div class="form-label">Gig Extension*</div>
                  <GigExtensionSelector
                    class="form-field ge-selector"
                    placeholder="Gig Extension"
                    v-model="form.gig_extension_id"
                    :rules="[validation.required]"
                    :params="{
                      creator_id: user ? user.id : form.user_id,
                    }"
                    :key="`ge-selector-${user ? user.id : form.user_id}`"
                  />
                </div>
                <div class="form-row">
                  <div class="form-label">Date Signed*</div>
                  <DateTimePicker
                    placeholder="Select Date"
                    v-model="form.signed_at_date"
                    onlyDate
                    light
                    class="form-field"
                    :rules="[validation.required]"
                  ></DateTimePicker>
                </div>
                <div class="form-row">
                  <div class="form-label">Date Requested*</div>
                  <DateTimePicker
                    placeholder="Select Date"
                    v-model="form.created_at_date"
                    onlyDate
                    light
                    class="form-field"
                    :rules="[validation.required]"
                  ></DateTimePicker>
                </div>
                <div class="form-row">
                  <div class="form-label">Upload*</div>
                  <div class="form-field signed-upload">
                    <div class="signed-upload__body">
                      <FileField
                        placeholder="Upload"
                        v-model="form.signed_upload"
                        :rules="[validation.required]"
                      />
                    </div>
                    <div class="signed-upload__actions">
                      <v-btn icon @click="form.signed_upload = null">
                        <v-icon size="24"> $delete </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div class="invoice-form__footer">
                <v-btn
                  type="submit"
                  color="#7B61FF"
                  elevation="0"
                  class="btn-submit"
                >
                  Submit
                </v-btn>
                <v-btn
                  @click="cancel"
                  elevation="0"
                  v-ripple="false"
                  color="#56565A"
                  class="btn-cancel"
                >
                  Cancel
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import GigExtensionSelector from "@/components/selects/GigExtensionSelector";
import HireRequestSelector from "@/components/selects/HireRequestSelector";
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import GigExtensionLabelsMixin from "@/mixins/gig-extension-labels.mixin";

export default {
  mixins: [InvoiceLabelsMixin, GigExtensionLabelsMixin],
  components: { GigExtensionSelector, HireRequestSelector },
  props: {
    dialog: Boolean,
    user: Object,
  },
  data() {
    const today = new Date();
    return {
      form: {
        user_id: null,
        agreement_type: null,
        gig_extension_id: null,
        hire_request_id: null,
      },
      agreementTypes: [
        { name: "Gig Contract", value: "gig_contract" },
        { name: "Gig Extension", value: "gig_extension" },
        { name: "Other", value: "other" },
      ],
      msaYears: [
        { value: today.getFullYear() },
        { value: today.getFullYear() + 1 },
      ],
      pleaseWait: false,
      hr: null,
      work_assignment_selections: [],
    };
  },
  mounted() {
    if (this.user) this.form.user_id = this.user.id;
  },
  methods: {
    typeChange() {
      this.form.hire_request_id = null;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.pleaseWait = true;
        try {
          this.form.transaction_id = uuidv4();
          this.$store.commit(
            "agreement/setTransactionId",
            this.form.transaction_id
          );
          const result = await this.$api.Agreement.uploadSigned(this.form);
          this.pleaseWait = false;
          if (result.status == 200) {
            this.$alert("Agreement uploaded successfullly", "success");
            this.cancel();
            this.$emit("refresh");
          }
        } catch (e) {
          this.pleaseWait = false;
          this.$alert("Something went wrong", "error");
          this.$emit("refresh");
        }
      }
    },
    cancel() {
      this.$emit("close");
      this.form = {
        user_id: this.user ? this.user.id : null,
        agreement_type: null,
        gig_extension_id: null,
        hire_request_id: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/payments/payments-dialog.scss";
</style>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";
@import "@/styles/gig-extension-form.scss";

.send-agreement {
  &__header {
    padding: 35px 44px 30px;
    border-bottom: 2px solid #262729;
  }

  &__title {
    color: #e12d1b;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 900;
  }
  &-dialog {
    .form-label {
      text-align: left;
      font-weight: bold;
      color: #56565a;
      margin-bottom: 0.35em;
      margin-right: 0.35em;
    }
  }

  &__form {
    padding: 21px 64px 30px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    form {
      width: 100%;
    }
    .form-row {
      margin-top: 1em;
    }

    > div {
      margin-bottom: 1em;
    }
    .col {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .v-input::v-deep,
    .hr-selector::v-deep .v-input,
    .ge-selector::v-deep .v-input,
    .datetime-picker::v-deep .v-input {
      label {
        top: 14px !important;
      }
      .v-text-field--enclosed .v-input__append-inner {
        margin-top: 14px !important;
      }
      .v-input__slot {
        min-height: 50px;
        padding-left: 27px !important;
      }
      &.v-input--is-disabled fieldset {
        border-color: #666 !important;
      }

      fieldset,
      .v-input__control fieldset {
        border-color: #262729;
        border-width: 2px;
      }
    }

    .v-btn.btn-cancel::before {
      display: none;
    }
    .v-btn.btn-submit {
      color: white !important;
    }

    .invoice-form__footer {
      .v-btn:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
}

.signed-upload {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__body {
    flex-grow: 1;
    margin-right: 1em;
    &::v-deep .uploader {
      border-color: #56565a !important;
      border-width: 2px !important;
      border-radius: 10px;
    }
  }
}
</style>
